<template>
  <div class="confirm_group" v-show="showButton">
    <button class="cancelBtn" @click="cancel">{{ cancelText }}</button>
    <ConfirmButton :text="confirmText" @click="confirm"></ConfirmButton>
  </div>
</template>

<script>
import ConfirmButton from "../Common/CommonElement/ConfirmButton.vue";
export default {
  name: "ConfirmButtonGroup",
  components: {
    ConfirmButton,
  },
  methods: {
    cancel() {
      this.$emit("cancel", "ruleForm");
    },
    confirm() {
      this.$emit("confirm", "ruleForm");
    },
  },
  props: ["cancelText", "showButton", "confirmText"],
};
</script>

<style lang="scss" scoped>
.confirm_group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 9vh;
  box-shadow: 5px 5px 10px 5px $main_gray;
  button {
    box-shadow: none;
  }
  .cancelBtn {
    width: 10rem;
    height: 3rem;
    border-radius: 1rem;
    font-weight: 700;
    color: $text_color;
    align-self: center;
    transition: $main_transition;
    font-size: 1rem;
    background-color: $main_gray;
    border: none;
    &:hover {
      background-color: #afafab;
    }
  }
}
</style>
