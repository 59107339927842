<template>
  <el-button class="btn">{{ text }}</el-button>
</template>

<script>
export default {
  name: "ConfirmButton",
  props: ["text"],
};
</script>

<style lang="scss" scoped>
.btn {
  padding: 0.5rem 0;
  border-radius: 2rem;
  font-weight: 700;
  color: $text_color;
  align-self: center;
  transition: $main_transition;
  background-color: $main_color;
  box-shadow: 0px 0px 3px 0px $main_color;
  font-size: 1rem;
  border: none;
  &:hover {
    background-color: $main_color_hover;
    color: $text_color;
  }
}
</style>
