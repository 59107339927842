<template>
  <div class="teacher_profile">
    <div class="top">
      <el-button
        color="#ffd964"
        size="medium"
        @click="changeToEdit"
        v-if="!isProfileClicked"
        >編輯</el-button
      >
      <el-button
        type="primary"
        size="medium"
        v-if="!isProfileClicked"
        @click="preview"
        >預覽</el-button
      >
    </div>
    <el-form
      @submit.prevent
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :label-position="labelPosition"
    >
      <div class="main input_form">
        <div class="left_side">
          <el-form-item prop="brief" label="簡介">
            <el-input
              ref="autoFocus"
              v-model="ruleForm.brief"
              placeholder="請輸入簡介"
              type="textarea"
              :autosize="{ minRows: 5 }"
              :disabled="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="introduction" label="自我介紹">
            <Editor
              :value="ruleForm.editorData"
              @input="getEditorData"
              :canNotEdit="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="teacherVideo" label="介紹影片">
            <el-input
              v-model="ruleForm.profileVideo"
              placeholder="請輸入Youtube連結"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="socialMedia_line" label="Line ID">
            <el-input
              v-model="ruleForm.socialMedia.line"
              placeholder="請輸入Line ID"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="socialMedia_fb" label="FB連結">
            <el-input
              v-model="ruleForm.socialMedia.fb"
              placeholder="請輸入FB連結"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="socialMedia_ig" label="IG連結">
            <el-input
              v-model="ruleForm.socialMedia.ig"
              placeholder="請輸入IG連結"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="socialMedia_twitter" label="Twitter連結">
            <el-input
              v-model="ruleForm.socialMedia.twitter"
              placeholder="請輸入Twitter連結"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="bankCode" label="銀行代碼(付款用)">
            <el-input
              v-model="ruleForm.bankCode"
              placeholder="請輸入銀行代碼"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
          <el-form-item prop="bankAccount" label="銀行帳戶(付款用)">
            <el-input
              v-model="ruleForm.bankAccount"
              placeholder="請輸入銀行帳戶"
              :readonly="isReadonly"
              :class="{ disActive: !isProfileClicked }"
            />
          </el-form-item>
        </div>
        <div class="right_side">
          <el-form-item
            prop="instrument"
            class="instrument hover_none"
            :class="{ nothing: isReadonly }"
          >
            <div class="label_icon">
              <label>教學科目</label>
              <el-popover
                placement="top-start"
                :width="200"
                trigger="hover"
                content="如果未找到您的教學科目，可於右下方點選新增科目！"
              >
                <template #reference>
                  <i class="fa-solid fa-circle-exclamation"></i>
                </template>
              </el-popover>
            </div>
            <el-cascader
              v-model="ruleForm.instrument"
              :options="$store.state.common.instrumentOptions"
              :props="props"
              :disabled="isReadonly"
              :class="{ disActive: !isProfileClicked }"
              clearable
              placeholder="請選擇可教學樂器"
            ></el-cascader>
          </el-form-item>
          <div
            class="text-end addInstrument"
            :class="{ active: !isShowAddInstrument }"
          >
            <el-button
              size="small"
              v-if="isShowAddInstrument"
              type="primary"
              round
              @click="isShowAddInstrument = false"
              data-bs-toggle="collapse"
              data-bs-target="#instrumentAdd"
              plain
              >新增科目</el-button
            >
            <div v-else>
              <el-button
                size="small"
                type="primary"
                round
                @click="addInstrument"
                plain
                >發送科目申請</el-button
              >
              <el-button
                size="small"
                type="primary"
                round
                @click="cancelAddInstrument"
                data-bs-toggle="collapse"
                data-bs-target="#instrumentAdd"
                plain
                >取消</el-button
              >
            </div>
            <el-form
              :model="addInstrumentForm"
              :rules="instrumentRules"
              ref="addInstrumentForm"
              class="collapse mt-1"
              id="instrumentAdd"
            >
              <el-form-item label="類別" prop="instrumentType">
                <el-select
                  v-model="addInstrumentForm.instrumentType"
                  placeholder="請選擇科目類別"
                  clearable
                >
                  <el-option
                    v-for="item in $store.state.common.instrumentTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="名稱" prop="instrumentName">
                <el-input
                  v-model="addInstrumentForm.instrumentName"
                  autocomplete="off"
                  placeholder="請輸入科目名稱"
                  clearable
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="teachingType hover_none" :class="{ nothing: isReadonly }">
            <el-form-item prop="teachingType" :props="props" label="教學類型">
              <el-cascader
                v-model="ruleForm.teachingType"
                :options="$store.state.common.teachingTypeOptions"
                :props="props"
                :disabled="isReadonly"
                :class="{ disActive: !isProfileClicked }"
                clearable
                placeholder="請選擇可教學類型"
              ></el-cascader>
            </el-form-item>
          </div>
          <div class="teachingArea hover_none" :class="{ nothing: isReadonly }">
            <el-form-item prop="teachingArea" :props="props" label="教學地點">
              <el-cascader
                v-model="ruleForm.teachingArea"
                :options="$store.state.common.teachingAreaOptions"
                :props="props"
                :disabled="isReadonly"
                :class="{ disActive: !isProfileClicked }"
                clearable
                placeholder="請選擇可教學地點"
              ></el-cascader>
            </el-form-item>
          </div>
          <div class="experience">
            <el-form-item prop="工作經驗" :props="props" label="工作經驗">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.experience"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.experience)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="experienceVisible"
                ref="experienceRef"
                v-model="experienceInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'experienceInput',
                    ruleForm.experience,
                    'experienceVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'experienceInput',
                    ruleForm.experience,
                    'experienceVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="showTagInput('experienceRef', 'experienceVisible')"
                :disabled="isReadonly"
                >新增工作經驗</el-button
              >
            </el-form-item>
          </div>
          <div class="education">
            <el-form-item prop="教育背景" :props="props" label="教育背景">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.education"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.education)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="educationVisible"
                ref="educationRef"
                v-model="educationInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'educationInput',
                    ruleForm.education,
                    'educationVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'educationInput',
                    ruleForm.education,
                    'educationVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="showTagInput('educationRef', 'educationVisible')"
                :disabled="isReadonly"
                >新增教育背景</el-button
              >
            </el-form-item>
          </div>
          <div class="certification">
            <el-form-item prop="證照證書" :props="props" label="證照證書">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.certification"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.certification)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="certificationVisible"
                ref="certificationRef"
                v-model="certificationInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'certificationInput',
                    ruleForm.certification,
                    'certificationVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'certificationInput',
                    ruleForm.certification,
                    'certificationVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="
                  showTagInput('certificationRef', 'certificationVisible')
                "
                :disabled="isReadonly"
                >新增證照證書</el-button
              >
            </el-form-item>
          </div>
          <div class="appointment">
            <el-form-item prop="預約方式" :props="props" label="預約方式">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.appointment"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.appointment)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="appointmentVisible"
                ref="appointmentRef"
                v-model="appointmentInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'appointmentInput',
                    ruleForm.appointment,
                    'appointmentVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'appointmentInput',
                    ruleForm.appointment,
                    'appointmentVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="showTagInput('appointmentRef', 'appointmentVisible')"
                :disabled="isReadonly"
                >新增預約方式</el-button
              >
            </el-form-item>
          </div>
          <div class="chargeDescription">
            <el-form-item prop="費用說明" :props="props" label="費用說明">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.chargeDescription"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.chargeDescription)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="chargeDescriptionVisible"
                ref="chargeDescriptionRef"
                v-model="chargeDescriptionInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'chargeDescriptionInput',
                    ruleForm.chargeDescription,
                    'chargeDescriptionVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'chargeDescriptionInput',
                    ruleForm.chargeDescription,
                    'chargeDescriptionVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="
                  showTagInput(
                    'chargeDescriptionRef',
                    'chargeDescriptionVisible'
                  )
                "
                :disabled="isReadonly"
                >新增費用說明</el-button
              >
            </el-form-item>
          </div>
          <div class="classDescription">
            <el-form-item prop="上課說明" :props="props" label="上課說明">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.classDescription"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.classDescription)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="classDescriptionVisible"
                ref="classDescriptionRef"
                v-model="classDescriptionInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'classDescriptionInput',
                    ruleForm.classDescription,
                    'classDescriptionVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'classDescriptionInput',
                    ruleForm.classDescription,
                    'classDescriptionVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="
                  showTagInput('classDescriptionRef', 'classDescriptionVisible')
                "
                :disabled="isReadonly"
                >新增上課說明</el-button
              >
            </el-form-item>
          </div>
          <div class="dropDescription">
            <el-form-item prop="退課須知" :props="props" label="退課須知">
              <el-tag
                class="mt-2"
                :key="tag"
                v-for="tag in ruleForm.dropDescription"
                closable
                :disable-transitions="false"
                @close="handleTagClose(tag, ruleForm.dropDescription)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                v-if="dropDescriptionVisible"
                ref="dropDescriptionRef"
                v-model="dropDescriptionInput"
                class="mt-2 w-20"
                size="small"
                @keydown.enter="
                  handleTagConfirm(
                    'dropDescriptionInput',
                    ruleForm.dropDescription,
                    'dropDescriptionVisible'
                  )
                "
                @blur="
                  handleTagConfirm(
                    'dropDescriptionInput',
                    ruleForm.dropDescription,
                    'dropDescriptionVisible'
                  )
                "
              />
              <el-button
                v-else
                class="button-new-tag mt-2"
                size="small"
                @click="
                  showTagInput('dropDescriptionRef', 'dropDescriptionVisible')
                "
                :disabled="isReadonly"
                >新增退課須知</el-button
              >
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
  <transition>
    <ConfirmButtonGroup
      class="ConfirmButtonGroup"
      :showButton="isConfirmButtonGroupShow"
      cancelText="取消"
      confirmText="確認修改"
      @cancel="changeToOrigin"
      @confirm="editCompleted"
    />
  </transition>
</template>

<script>
import ConfirmButtonGroup from "../../../components//Common/ConfirmButtonGroup.vue";
import Editor from "../../../components//Common/Editor.vue";

export default {
  name: "TeacherProfile",
  components: {
    ConfirmButtonGroup,
    Editor,
  },
  async created() {
    await this.getInfo();
  },
  data() {
    return {
      labelPosition: "top",
      props: { multiple: true },
      isProfileClicked: false,
      isReadonly: true,
      isConfirmButtonGroupShow: false,
      isShowAddInstrument: true,
      experienceVisible: false,
      experienceInput: "",
      educationVisible: false,
      educationInput: "",
      certificationVisible: false,
      certificationInput: "",
      appointmentVisible: false,
      appointmentInput: "",
      chargeDescriptionVisible: false,
      chargeDescriptionInput: "",
      classDescriptionVisible: false,
      classDescriptionInput: "",
      dropDescriptionVisible: false,
      dropDescriptionInput: "",
      backup: {},
      addInstrumentForm: {
        instrumentType: "",
        instrumentName: "",
      },
      ruleForm: {
        memberId: "",
        brief: "",
        editorData: "",
        profileVideo: "",
        teachingType: [],
        instrument: "",
        teachingArea: "",
        experience: [],
        education: [],
        certification: [],
        appointment: [],
        chargeDescription: [],
        classDescription: [],
        dropDescription: [],
        bankCode: "",
        bankAccount: "",
        socialMedia: {
          line: "",
          fb: "",
          ig: "",
          twitter: "",
        },
      },
      rules: {
        bankCode: [
          {
            pattern: /^[0-9]{3}$/,
            message: "請輸入正確銀行代碼格式",
            trigger: "blur",
          },
        ],
        bankAccount: [
          {
            pattern: /^[0-9]{10,14}$/,
            message: "請輸入正確銀行帳戶格式",
            trigger: "blur",
          },
        ],
      },
      instrumentRules: {
        instrumentType: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        instrumentName: [
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    preview() {
      var route = this.$router.resolve({
        path: `/TeacherIntroduce/${this.ruleForm.memberId}`,
      });
      window.open(route.href, "_blank");
    },
    getEditorData(input) {
      this.ruleForm.editorData = input;
    },
    async getInfo() {
      const vm = this;
      vm.fetchAPI(
        "get",
        `${process.env.VUE_APP_URL_API}/teacher/${vm.$store.state.auth.user.id}/info`
      )
        .then((res) => {
          vm.ruleForm = {
            memberId: res.data.memberId,
            brief: res.data.simpleIntroduction,
            editorData: res.data.selfIntroduction,
            profileVideo: res.data.introVideoUrl,
            teachingType: res.data.teachingType.split(",").map((x) => [x]),
            instrument: res.data.instrument,
            teachingArea: res.data.teachingArea
              .split(",")
              .map((x) => Number(x)),
            experience: vm.checkArrayNull(res.data.workExperiences),
            education: vm.checkArrayNull(res.data.educationBackgrounds),
            certification: vm.checkArrayNull(res.data.licenses),
            appointment: vm.checkArrayNull(res.data.reserveMethod),
            chargeDescription: vm.checkArrayNull(res.data.payIntro),
            classDescription: vm.checkArrayNull(res.data.courseIntro),
            dropDescription: vm.checkArrayNull(res.data.refundIntro),
            bankCode: res.data.bankCode,
            bankAccount: res.data.bankAccount,
            socialMedia:
              res.data.socialMedia == ""
                ? { line: "", fb: "", ig: "", twitter: "" }
                : JSON.parse(res.data.socialMedia),
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelAddInstrument() {
      this.$refs["addInstrumentForm"].resetFields();
      this.isShowAddInstrument = true;
      (this.addInstrumentForm = {
        instrumentType: "",
        instrumentName: "",
      }),
        document.getElementById("instrumentAdd").classList.remove("show");
    },
    async addInstrument() {
      const vm = this;
      vm.$refs["addInstrumentForm"].validate((valid) => {
        if (valid) {
          let data = {
            InstrumentType: vm.addInstrumentForm.instrumentType,
            InstrumentName: vm.addInstrumentForm.instrumentName,
          };
          vm.fetchAPI(
            "post",
            `${process.env.VUE_APP_URL_API}/Instruments/apply`,
            data
          )
            .then(() => {
              vm.notify("success", "新增科目成功！");
            })
            .catch((err) => {
              console.log(err);
              vm.notify("error", "新增科目失敗！");
            })
            .finally(() => {
              document.getElementById("instrumentAdd").classList.remove("show");
              vm.$refs["addInstrumentForm"].resetFields();
              vm.isShowAddInstrument = true;
              vm.addInstrumentForm = {};
            });
        }
      });
    },
    changeToEdit() {
      Object.assign(this.backup, this.ruleForm);
      this.isReadonly = false;
      this.isProfileClicked = true;
      this.isConfirmButtonGroupShow = true;
      this.$refs.autoFocus.focus();
    },
    changeToOrigin(formName) {
      this.$refs[formName].resetFields();
      Object.assign(this.ruleForm, this.backup);
      this.isReadonly = true;
      this.isProfileClicked = false;
      this.isConfirmButtonGroupShow = false;
    },
    editCompleted() {
      const vm = this;
      vm.checkEditorBox();
      let data = {
        SimpleIntroduction: vm.ruleForm.brief,
        SelfIntroduction: vm.ruleForm.editorData,
        IntroVideoUrl: vm.ruleForm.profileVideo,
        TeachingType: vm.changeDot(vm.ruleForm.teachingType, false),
        Instrument: vm.toTurnArrayType(vm.ruleForm.instrument, true),
        TeachingArea: vm.turnTeachingAreaToString(vm.ruleForm.teachingArea),
        WorkExperiences: vm.ruleForm.experience,
        EducationBackgrounds: vm.ruleForm.education,
        Licenses: vm.ruleForm.certification,
        ReserveMethod: vm.ruleForm.appointment,
        PayIntro: vm.ruleForm.chargeDescription,
        CourseIntro: vm.ruleForm.classDescription,
        RefundIntro: vm.ruleForm.dropDescription,
        BankCode: vm.ruleForm.bankCode,
        BankAccount: vm.ruleForm.bankAccount,
        SocialMedia: JSON.stringify(vm.ruleForm.socialMedia),
        Cover: "",
      };
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/teacher/info`, data)
        .then(() => {
          vm.notify("success", "老師資料更新成功！");
          vm.isReadonly = true;
          vm.isProfileClicked = false;
          vm.isConfirmButtonGroupShow = false;
        })
        .catch((err) => {
          console.log(err);
          Object.assign(vm.ruleForm, vm.backup);
          vm.notify("error", "老師資料更新失敗！");
        });
    },
    handleTagClose(tag, container) {
      if (this.isReadonly) return;
      container.splice(container.indexOf(tag), 1);
    },
    showTagInput(refName, visible) {
      this[visible] = true;
      this.$nextTick(() => {
        this.$refs[refName].$refs.input.focus();
      });
    },
    handleTagConfirm(inputValue, container, visible) {
      if (this[inputValue]) {
        container.push(this[inputValue]);
      }
      this[visible] = false;
      this[inputValue] = "";
    },
    turnTeachingAreaToString(arr) {
      if (arr.length != 0 && typeof arr[0] != "object") return arr.join(",");
      return arr.map((x) => x[x.length - 1]).join(",");
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher_profile {
  text-align: start;
  padding: 0 1.5rem 4rem;
  .top {
    display: flex;
    justify-content: flex-end;
    .title {
      display: flex;
      align-items: center;
      i {
        color: $main_color;
        font-size: 1.2rem;
        margin-right: 0.5em;
      }
      h2 {
        font-size: 1.1rem;
        font-weight: $main_fontWeight;
      }
    }
  }
  .main {
    .left_side {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 2px solid $main_color;
    }
    .right_side {
      .instrument {
        padding-top: 1.5rem;
        border-top: 2px solid $main_color;
        margin-bottom: 0;
      }
      .addInstrument {
        padding: 0;
        &.active {
          padding: 1rem;
          border: 1px solid $main-gray;
          margin: 1rem 0;
          border-radius: 1rem;
        }
      }
    }
  }
}
.ConfirmButtonGroup {
  z-index: 30000 !important;
}
:deep .el-form-item {
  .el-cascader {
    width: 100%;
  }
  .button-new-tag {
    width: 100%;
  }
  .el-input.disActive {
    .el-input__inner {
      cursor: default;
    }
    .el-input__wrapper {
      box-shadow: none;
    }
  }
}
.hover_none.nothing {
  pointer-events: none;
  :deep .el-input__suffix {
    display: none;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity $main_transition;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.v-enter-to,
.v-leave-from {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .teacher_profile {
    .main {
      display: flex;
      padding-bottom: 2rem;
      justify-content: space-between;
      border-top: 2px solid $main_color;
      margin-top: 0.5rem;
      .left_side {
        justify-content: flex-start;
        flex-direction: column;
        border: none;
        width: 40vw;
      }
      .right_side {
        width: 50vw;
        padding-top: 1rem;
        padding-left: 5vw;
        border-left: 2px solid $main_color;
        .instrument {
          padding-top: 0;
          border: none;
        }
      }
    }
  }
}
</style>
